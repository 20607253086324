import { useCallback } from 'react';
import { UserSubscriptionStatus } from '@remento/types/user';

import { useProjectQuery } from '@/services/api/project';

import { showProjectPaywall } from './paywall.state';

export function useShowPaywall(projectId: string | null | undefined): () => boolean {
  const projectQuery = useProjectQuery(projectId);

  return useCallback(() => {
    if (projectQuery.data?.subscriptionStatus === UserSubscriptionStatus.INACTIVE) {
      showProjectPaywall(projectQuery.data.id);
      return true;
    }
    return false;
  }, [projectQuery.data?.id, projectQuery.data?.subscriptionStatus]);
}
